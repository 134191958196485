@import "../../../../src/assets/styles/shared.scss";

.dashboardWrapper {
  width: 100%;
  .topbar {
    background: $darkgreen;
    height: 69px;
    @include flex-all-center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    i {
      position: absolute;
      top: 13px;
      left: 20px;
      background: rgba(255, 255, 255, 0.5);
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      cursor: pointer;
    }
  }
  .sidebar {
    position: fixed;
    top: 69px;
    left: 0;
    height: 100%;
    width: 260px;
    background: $defaultheadingcolor;
    z-index: 100;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    @media (max-width: 668px){
      width: 78px;
    }
    .nav-links {
      padding: 0;
      width: 100%;
      ::-webkit-scrollbar {
        display: none;
      }
      li {
        position: relative;
        list-style: none;
        transition: all 0.4s ease;
        padding-left: 60px;
        @media (max-width: 668px){
          padding-left: 12px;
        }
        .icon-link {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &:hover {
          background: $darkgreen;
        }
        i {
          height: 50px;
          min-width: 50px;
          text-align: center;
          line-height: 45px;
          color: #fff;
          font-size: 23px;
          cursor: pointer;
          transition: all 0.3s ease;
        }
        p {
          display: flex;
          align-items: center;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
    .bottomLink {
      position: absolute;
      bottom: 69px;
      left: 0;
      width: 100%;
      margin-top: 0;
      cursor: pointer;
    }
  }
  .close {
    width: 78px;
    .logo_name {
      transition-delay: 0s;
      opacity: 0;
      pointer-events: none;
    }
    .nav-links {
      overflow: visible;
      li {
        padding-left: 12px;
        .icon-link {
          display: block;
        }
      }
    }
  }

  .sidebar .nav-links li.showMenu i.arrow {
    transform: rotate(-180deg);
  }
  .sidebar.close .nav-links i.arrow {
    display: none;
  }
  .sidebar .nav-links li p .link_name {
    font-size: 15px;
    font-weight: 400;
    color: $defaultcolor;
    transition: all 0.4s ease;
    white-space: nowrap;
    @media (max-width: 668px){
      opacity: 0;
      pointer-events: none;
    }
  }
  .sidebar.close .nav-links li p .link_name {
    opacity: 0;
    pointer-events: none;
  }
  .sidebar .nav-links li .sub-menu {
    padding: 6px 6px 14px 80px;
    margin-top: -10px;
    background: #1d1b31;
    display: none;
  }
  .sidebar .nav-links li.showMenu .sub-menu {
    display: block;
  }
  .sidebar .nav-links li .sub-menu p {
    color: #fff;
    font-size: 15px;
    padding: 5px 0;
    white-space: nowrap;
    opacity: 0.6;
    transition: all 0.3s ease;
  }
  .sidebar .nav-links li .sub-menu p:hover {
    opacity: 1;
  }
  .sidebar.close .nav-links li .sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
  }
  .sidebar.close .nav-links li:hover .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
  }
  .sidebar .nav-links li .sub-menu .link_name {
    display: none;
  }
  .sidebar.close .nav-links li .sub-menu .link_name {
    font-size: 18px;
    opacity: 1;
    display: block;
  }
  .sidebar .nav-links li .sub-menu.blank {
    opacity: 1;
    pointer-events: auto;
    padding: 3px 20px 6px 16px;
    opacity: 0;
    pointer-events: none;
  }
  .sidebar .nav-links li:hover .sub-menu.blank {
    top: 50%;
    transform: translateY(-50%);
  }
  .sidebar .profile-details {
    position: fixed;
    bottom: 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1d1b31;
    padding: 12px 0;
    transition: all 0.5s ease;
  }
  .sidebar.close .profile-details {
    background: none;
  }
  .sidebar.close .profile-details {
    width: 78px;
  }
  .sidebar .profile-details .profile-content {
    display: flex;
    align-items: center;
  }
  .sidebar .profile-details img {
    height: 52px;
    width: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #1d1b31;
    transition: all 0.5s ease;
  }
  .sidebar.close .profile-details img {
    padding: 10px;
  }
  .sidebar .profile-details .profile_name,
  .sidebar .profile-details .job {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
  }
  .sidebar.close .profile-details i,
  .sidebar.close .profile-details .profile_name,
  .sidebar.close .profile-details .job {
    display: none;
  }
  .sidebar .profile-details .job {
    font-size: 12px;
  }
  .sidebar.close ~ .home-section {
    left: 78px;
    width: calc(100% - 78px);
  }
  .sidebar.close ~ .home-section .repeatSec .boxCol {
    width: 28.8% !important;
  }
  @media (max-width: 420px) {
    .sidebar.close .nav-links li .sub-menu {
      display: none;
    }
  }
}
