.delresPopup{
    .popup-content{
      width: 500px !important;
      z-index: 9999;
      @media (max-width: 668px) {
        width: 75%;
      }
      h2{
        padding-bottom: 25px;
      }
      p{
        color: #242424;
        font-size: 16px;
        padding: 0 50px;
      }
      .delCancelBtn{
        margin-right: 15px;
        padding: 10px 80px !important;
        margin-top: 0 !important;
        font-weight: 500;
        border: solid 2px #32807F;
        border-radius: 70px;
        background: white;
        color: #32807F;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        margin-left: 10px;        
        &:hover{
          background:#32807F;
          color: #fff;
        }
      }
  .delSubmitBtn2{
    margin-right: 15px;
    padding: 10px 30px !important;
    margin-top: 0 !important;
    font-weight: 500;
    border: solid 2px #C22326;
    border-radius: 70px;
    background: #C22326;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    display: flex;
    min-width: 250px;
    justify-content: center;
    @media (max-width: 668px) {
      padding: 10px 15px !important;
      margin-left: 0;
      font-size: 14px;
      margin-right: 0;
      min-width: auto;
    }
    &:hover{
      background:#911618;
      border: solid 2px #911618;
    }
    .loading-spinner{
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }
  
    }
    &:after{
      z-index: 999;
    }  
  }
  