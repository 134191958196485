@import "../../../../../src/assets/styles/shared.scss";

.dashboardWrapper {
  width: 100%;
  .home-section {
    position: relative;
    background: $defaultcolor;
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
    margin-top: 69px;
    padding: 30px 0;
    .homeContent {
      padding: 0 0 0 40px;
      @media (max-width: 668px) {
        padding: 0 0 0 20px;
      }
      .bodyTopsec {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        @media (max-width: 668px) {
          padding-right: 20px;
          flex-wrap: wrap;
          align-items: center;
        }
        h2 {
          color: $blackcolor;
          font-size: 28px;
          font-weight: 600;
          @media (max-width: 668px) {
            font-size: 22px;
          }
        }
        .iconSce {
          display: flex;
          @media (max-width: 668px) {
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .bxs-pencil {
            background: $yellowbg;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            cursor: pointer;
            margin-right: 15px;
            color: $defaultcolor;
          }
          .bx-plus {
            background: $darkgreen;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            cursor: pointer;
            color: $defaultcolor;
          }
          .doneBtn {
            border: solid 3px $yellowbg;
            border-radius: 30px;
            padding: 10px 65px;
            color: #fec933;
            text-decoration: none;
            line-height: 23px;
            margin-right: 15px;
            font-size: 16px;
            font-weight: 700;
            &:hover {
              background: $yellowbg;
              color: $blackcolor;
            }
          }

          .searchSec {
            width: 400px;
            margin-right: 15px;
            display: flex;
            position: relative;
            @media (max-width: 668px) {
              width: 100%;
            }
            &:after {
              content: "";
              position: absolute;
              top: 15px;
              right: 15px;
              background: url(../../../../assets//images/search-icon.png) 0 0
                no-repeat;
              width: 20px;
              height: 20px;
            }
            input {
              border: solid 1px #8e8e8e;
              padding: 12px 20px 15px;
              width: 100%;
              font-size: 16px;
            }
          }
          .filterSec {
            display: flex;
            align-items: center;
            margin: 0 15px;
            position: relative;
            @media (max-width: 668px) {
              margin: 0 15px 0 0;
            }
            a{
              @media (max-width: 668px) {
                background: #fff;
                box-shadow: 0 0 5px #ccc;
                border-radius: 100%;
                width: 40px;
                height: 40px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              img{
                @media (max-width: 668px) {
                  width: 25px;
                  margin-top: 2px;
                }
              }
            }
          }
        }
      }
    }
    .prcon20{
      padding-right: 0;
      @media (max-width: 668px) {
        padding-right: 20px !important;
      }
    }
  }

  .repeatSec {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    .categoryHeaderSection{
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
    h3 {
      font-size: 18px !important;
      color: $blackcolor;
      font-weight: 600;
      padding-bottom: 10px;
      i {
        color: $darkgreen;
        font-size: 15px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .flexRow {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .boxCol {
        background: rgba(246, 246, 246, 1);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 12px 0;
        width: 28%;
        margin-right: 20px;
        margin-bottom: 15px;
        @media (max-width: 668px) {
          width: 100%;
          margin-right: 0;
        }
        .flexSec {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .iconSec {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;
            .bxs-pencil {
              background: $yellowbg;
              font-size: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              border-radius: 100%;
              cursor: pointer;
              color: $defaultcolor;
              @media (max-width: 668px) {
                width: 35px;
                height: 35px;
                font-size: 15px;
              }
            }
            .bxs-trash {
              background: $defaultheadingcolor;
              font-size: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              border-radius: 100%;
              cursor: pointer;
              color: $defaultcolor;
              margin-right: 10px;
              @media (max-width: 668px) {
                width: 35px;
                height: 35px;
                font-size: 15px;
              }
            }
          }
        }
        p {
          font-size: 16px;
          padding: 4px 0;
        }
      }
    }
  }
}

.popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
  }
  .popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 35px 50px;
    z-index: 999;
    width: 450px;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    @media (max-width: 668px) {
      padding: 30px 30px;
      width: 75%;
    }
    @media (max-width: 450px) {
      padding: 30px 0 30px 30px;
    }
    form {
      @media (max-width: 450px) {
        overflow-x: scroll;
        max-height: 350px;
        padding-right: 10px;
      }
    }
    .bx-x {
      font-size: 40px;
      color: $blackcolor;
      position: absolute;
      right: 40px;
      top: 50px;
      cursor: pointer;
      @media (max-width: 480px) {
        right: 15px;
        top: 25px;
      }
    }
    h2 {
      font-size: 24px;
      color: $blackcolor;
      font-weight: 600;
      padding-bottom: 40px;
      @media (max-width: 668px) {
        padding-bottom: 20px;
      }
    }
    .flexRow {
      display: flex;
      justify-content: space-between;
      @media (max-width: 668px) {
        flex-wrap: wrap;
      }
      .formCol {
        width: 100%;
        .form-group {
          margin-bottom: 20px;
          @media (max-width: 450px) {
            margin-bottom: 10px;
          }
          label {
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 5px;
            display: block;
            text-align: left;
          }
          .dFlexTextarea {
            display: flex;
            textarea {
              padding: 13px 15px 16px;
              border: 1px solid #dfdfdf;
              background-color: #fafafa;
              color: $grey;
              font-size: 14px;
              width: 100%;
            }
          }

          .form-control {
            @include flex;
            flex-direction: column;
            input {
              padding: 13px 15px 16px;
              border: 1px solid #dfdfdf;
              background-color: #fafafa;
              color: $grey;
              font-size: 14px;
            }
            .error-text {
              font-size: 13px;
              color: red;
              margin-top: 5px;
              text-align: left;
            }
          }

          textarea {
            padding: 13px 15px 16px;
            border: 1px solid #dfdfdf;
            background-color: #fafafa;
            color: $grey;
            font-size: 14px;
          }
        }
      }
      .colOne {
        width: 100% !important;
      }
    }
    .btn {
      margin-top: 20px;
      padding: 13px 70px;
      font-size: 16px;
      color: $defaultcolor;
      background-color: $darkgreen;
      border: none;
      border-radius: 70px;
      cursor: pointer;
      transition:none;
      float: right;
      width: 260px;
      @media (max-width: 668px) {
        padding: 12px 20px;
        width: auto;
        min-width: 170px;
      }
      &:hover {
        background-color: $defaultheadingcolor;
        box-shadow: none;
        transition:none;
      }
    }
    .cancleBtn {
      // margin-top: 20px;
      padding: 12px 60px;
      font-size: 16px;
      color: $darkgreen;
      background-color: $defaultcolor;
      border: solid 1px $darkgreen;
      border-radius: 70px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      float: left;
      margin-top: 0;
      @media (max-width: 668px) {
        padding: 12px 40px;
      }
      &:hover {
        background-color: $darkgreen;
        color: $defaultcolor;
      }
    }
  }
  .longWith {
    width: 750px;
    @media (max-width: 668px) {
      width: 75%;
    }
    h2 {
      text-align: left;
    }
    .formCol {
      width: 48% !important;
      position: relative;
      @media (max-width: 450px) {
        width: 100% !important;
      }
    }
    .fullWidth {
      width: 100% !important;
      position: relative;
      .dFlex {
        display: flex;
        textarea {
          width: 100%;
          margin-top: 5px;
          height: 50px;
        }
      }
    }
  }
  .popup button {
    margin-top: 10px;
  }

  .flxendBtn {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 668px) {
      justify-content: space-between;
    }
    .cancleBtn {
      margin-right: 15px;
      padding: 12px 95px;
      @media (max-width: 668px) {
        padding: 12px 40px;
      }
    }
  }

  .flxendrightBtn {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 668px) {
      justify-content: space-between;
    }
    .cancleBtn {
      margin-right: 15px;
      padding: 12px 95px;
      @media (max-width: 668px) {
        padding: 12px 40px;
      }
    }
  }
}

.selectTag .css-t7xmv1-IndicatorsContainer {
  display: none;
}
.selectTag .css-4dmm19-multiValue .css-pjm3ym-MultiValueRemove {
  margin-right: 5px !important;
}
.error-wrapper {
  font-size: 13px;
  color: red;
  margin-top: 5px;
  text-align: left;
}
.infiniteScroll{
  overflow: auto;
  display: flex;
  height: 80vh;
  margin-right: 40px;
  &::-webkit-scrollbar {
    display:none;
  }
}

.filterPopup{
  h2{
    font-size: 32px !important;
    font-weight: 700;
    padding-bottom: 20px !important;
  }
}
.filterList{
  h3{
    text-align: left;
    font-size: 16px;
    color: #242424;
    padding-bottom: 15px;
  }
  ul{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  li{
    display: flex;
    width: 50%;
    margin-bottom: 10px;
  }
  }
}

.filterformGroup{
 input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
  label {
    position: relative;
    cursor: pointer;
    font-size: 15px;
    color: #ABABAB;
    width: 70% !important;
    float: left;
    display: inline-block;
    word-break: break-word;
    text-align: left;
    padding-left: 30px;
    &:before {
      content:'';
      -webkit-appearance: none;
      background-color: rgba(125, 33, 57, 0.25);
      border: 1px solid #7D2139;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 13px;
      width: 18px;
      height: 18px;
    }
  }
}
.filterformGroup input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3.5px;
  left: 7.5px;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.filterformGroup input:checked + label:before{
  background: #7D2139;
}


.dealbtn {
  margin-top: 0;
  padding: 12px 60px;
  font-size: 16px;
  color: $defaultcolor;
  background-color: $darkgreen;
  border: none;
  border-radius: 70px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-weight: 500;
  float: right;
  text-decoration: none;
  width: 200px;
  @media (max-width: 668px){
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
  &:hover{
    background-color: $defaultheadingcolor;
  }
}


.overlapPop{
  &:after{
    z-index: 999 !important;
  }
}


.doneBtnmenu {
  margin-top: 0;
  padding: 12px 95px;
  font-size: 16px;
  color: $defaultcolor;
  background-color: $darkgreen;
  border: none;
  border-radius: 70px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  float: right;
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: auto;
  @media (max-width: 667px) {
    padding: 12px 30px;
  }
  &:hover{
    background-color: $defaultheadingcolor;
  }
  .loading-spinner-container{
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-top: -7px;
    margin-right: 20px;
  }
  .loading-spinner {
    width: 15px;
    height: 15px;
}
}
