.css-13cymwt-control {
  min-height: 49px !important;
  border-radius: 0 !important;
  margin-top: 5px !important;
  border: 1px solid #dfdfdf;
  background-color: #fafafa !important;
  color: #ababab;
  font-size: 14px;
  text-align: left;
}
.css-13cymwt-control:hover {
  border: 1px solid #dfdfdf !important;
  outline: none;
}
.css-t3ipsp-control {
  min-height: 49px !important;
  border-radius: 0 !important;
  margin-top: 5px !important;
  border: 1px solid #dfdfdf;
  background-color: #fafafa !important;
  color: #ababab !important;
  font-size: 14px;
  text-align: left;
}
.css-t3ipsp-control:hover {
  border: 1px solid #dfdfdf !important;
  outline: none;
}
.css-1vsd43b-control {
  min-height: 49px !important;
  border-radius: 0 !important;
  margin-top: 5px !important;
  border: 1px solid #dfdfdf !important;
  background-color: #fafafa !important;
  color: #ababab;
  font-size: 14px;
  text-align: left;
  font-weight: normal;
}
.css-1vsd43b-control:hover {
  border: 1px solid #dfdfdf !important;
  outline: none;
}
.css-124r16k-control {
  min-height: 49px !important;
  border-radius: 0 !important;
  margin-top: 5px !important;
  border: 1px solid #dfdfdf !important;
  background-color: #fafafa !important;
  color: #ababab;
  font-size: 14px;
  text-align: left;
}
.css-124r16k-control:hover {
  border: 1px solid #dfdfdf !important;
  outline: none;
  border-color: #dfdfdf !important;
}
.css-124rl6k-control:hover {
  border-color: #dfdfdf;
}

.addImageList {
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
  display: flex;
}
.addImageList li {
  background: #d9d9d9;
  border-radius: 2px;
  width: 100px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-right: 20px;
  position: relative;
}
.addImageList li.addSec {
  background: rgba(125, 33, 57, 0.25);
  border-radius: 2px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-right: 20px;
}
.addImageList li i {
  font-size: 30px;
  color: #7d2139;
}
.addMenuPop .scrollbar {
  max-height: 400px;
}
.removeIcon {
  position: absolute;
  top: -7px;
  right: -10px;
  cursor: pointer;
}
.addImageList .previewImage {
  width: 100%;
  height: 100%;
}
.addImageList .removeIcon i {
  color: #c22326 !important;
  font-size: 27px !important;
}

.file_up {
  background: transparent;
  height: 100px;
  width: 100%;
  color: #696969;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.file_up:hover,
.file_up:active {
  background: transparent;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}


.editCategorypop{
  width: 502px !important;
  @media (max-width: 668px){ 
    width: 80% !important;
  }
  .withFull{
    width: 100% !important;
    margin-bottom: 20px;
  }
  .flxendBtn{
    margin-top: 40px;
  }
}
.McircleIcon{
  background: rgba(194, 35, 38, 1);
  border-radius:50%;
  width:25px;
  height:25px;
  display: flex;
  justify-content:center;  
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.css-db2zx6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  color: rgb(255, 255, 255);
  font-size: 85%;
  padding: 7px 3px 3px 6px !important;
  box-sizing: border-box;
}
