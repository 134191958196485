@import "../../../../../src/assets/styles/shared.scss";

.dashboardWrapper {
  width: 100%;
  .home-section {
    .home-content {
      padding: 0 0 0 40px;
      .dealbodyTopsec {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        @media (max-width: 668px){ 
          padding-right: 20px;
        }
        h2 {
          color: $blackcolor;
          font-size: 28px;
          font-weight: 600;
          @media (max-width: 668px){ 
            font-size: 22px;
          }
        }
        .iconSce {
          display: flex;
          .bxs-pencil {
            background: $yellowbg;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            cursor: pointer;
            margin-right: 15px;
            color: $defaultcolor;
          }
          .bx-plus {
            background: $darkgreen;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            cursor: pointer;
            color: $defaultcolor;
          }
          .doneBtn {
            border: solid 3px $yellowbg;
            border-radius: 30px;
            padding: 10px 65px;
            color: #fec933;
            text-decoration: none;
            line-height: 23px;
            margin-right: 15px;
            font-size: 16px;
            font-weight: 700;
            &:hover {
              background: $yellowbg;
              color: $blackcolor;
            }
          }
        }
      }

      .repeatSec {
        width: 100%;
        margin-top: 20px;
        h3 {
          font-size: 20px;
          color: $blackcolor;
          font-weight: 600;
          padding-bottom: 10px;
        }
        .flexRow {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .flexleftCol{
            width: 80%;
          }
          .flexrightCol{
            display: flex;
            align-items: center;
            p{
              font-size: 16px;
              color: #252525;
              text-align: center;
              font-weight: 500;
              padding-right: 25px !important;
              span{
                display: block;                
              }
            }
            .dropdownSec{
              position: relative;
              i{
                color: #D9D9D9;
                font-size: 25px;
                cursor: pointer;
              }
              ul{
                background:#fff;
                visibility: hidden;
                opacity: 0;
                min-width:11rem;
                position: absolute;
                transition: all 0.5s ease;
                margin-top: 1rem;
                right: 0;
                top: 5px;
                display: none;
                border-radius: 10px;
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
                list-style: none;
                padding: 0;
                li{
                  border-bottom: solid 1px #F2F2F2;
                  padding: 12px 0;
                  text-align: center;
                  cursor: pointer;
                  font-size: 16px;
                  color: #252525;
                }
              }              
              &:hover > ul{
                visibility: visible;
                opacity: 1;
                display: block;
              }
            }
            
          }
          .boxCol {
            background: rgba(246, 246, 246, 1);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 12px 20px;
            width:46%;
            margin-right: 0;
            margin-bottom: 15px;
            @media (max-width: 1300px){ 
              width:45%;
            }
            @media (max-width: 668px){ 
              width:100%;
            }
            .flexSec {
              display: flex;
              justify-content: space-between;
              .iconSec {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .bxs-pencil {
                  background: $yellowbg;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
                  border-radius: 100%;
                  cursor: pointer;
                  color: $defaultcolor;
                }
                .bxs-trash {
                  background: $defaultheadingcolor;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
                  border-radius: 100%;
                  cursor: pointer;
                  color: $defaultcolor;
                  margin-right: 10px;
                }
              }
            }
            p {
              font-size: 16px;
              padding: 2px 0;
            }
            .greyText{
              font-size: 15px;
              color: #7C7C7C;
              padding: 2px 0;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
}

.popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;    
  &:after{
    content: "";
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
  }
  .popup-content2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 40px 35px;
    z-index: 999;     
    width:450px;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    @media (max-width: 668px){ 
      width:75%;
    }
    .bx-x{
      font-size: 40px;
      color: $blackcolor;
      position: absolute;
      right: 40px;
      top: 50px;
      cursor: pointer;
    }
    h2{
      font-size: 32px;
      color:$blackcolor;
      font-weight:700;
      padding-bottom: 35px;
      text-align: center;
    }
    .flexRow{
      display: flex;
      justify-content: space-between;
      .formCol{
        width: 100%;
        .form-group{
          margin-bottom: 20px;
          label {
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 5px;
            display: block;
            text-align: left;
          }
          .form-control {
            @include flex;
            flex-direction: column;
            input {
              padding: 13px 15px 16px;
              border: 1px solid #DFDFDF;
              background-color: #FAFAFA;
              color: $grey;
              font-size: 14px;
            }
            .error-text {
              font-size: 13px;
              color: red;
              margin-top: 5px;
              text-align: left;               
            }
          }

        }
      }
     
    }
    .dealbtn {
      margin-top: 0;
      padding: 12px 20px;
      font-size: 16px;
      color: $defaultcolor;
      background-color: $darkgreen;
      border: none;
      border-radius: 70px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      font-weight: 500;
      float: right;
      text-decoration: none;
      width: auto;
      @media (max-width: 668px){ 
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        text-align: center;
      }
      &:hover{
        background-color: $defaultheadingcolor;
      }
    }
  }
  .popup button {
    margin-top: 10px;
  }
 
}

.flxendBtn{
  justify-content: space-between !important;
  margin-top: 20px;
  @media (max-width: 668px){ 
    flex-wrap: wrap;
  }
  .dealCancleBtn {
    margin-right: 15px;
    padding: 12px 60px !important;
    margin-top: 0 !important;
    font-weight: 500;
    border:solid 2px $darkgreen;
    border-radius: 70px;
    background: $defaultcolor;
    color: $darkgreen;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 668px){ 
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    &:hover{
      background: $darkgreen;
      color: $defaultcolor;
    }
  }

  .dealsuccessBTn {
    margin-top: 0;
    padding: 12px 80px;
    font-size: 16px;
    color: $defaultcolor;
    background-color: $darkgreen;
    border: none;
    border-radius: 70px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-weight: 500;
    float: right;
    text-decoration: none;
    @media (max-width: 668px){ 
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    &:hover{
      background-color: $defaultheadingcolor;
    }
  }
}

.moreLink {
  cursor: pointer;
}

.moreLinkLess {
  cursor: pointer;
  display: block;
}


.claimCheckRow{
  display: flex;
  margin-top: 10px;
  .claimCheckSection{
    margin-right: 25px;
    label {
      position: relative;
      cursor: pointer;
      &:before {
        content:'';
        appearance: none;
        background-color: transparent;
        border: 2px solid #7D2139;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 8px;
        border-radius: 2px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
        margin-top: -2px;
      }
    }
    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
      &:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 1.5px;
        left: 7px;
        width: 4px;
        height: 10px;
        border: solid #7D2139;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}


.doneBtnLoader{
  display: flex;
  align-items: center;
  .loading-spinner{
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}


