@import "../../../../../src/assets/styles/shared.scss";

.login-wrapper {
  background-color: $yellowbg;
  height: 100vh;
  @include flex-all-center;
  @media only screen and (max-width: 768px) {
    height: auto;
  }
  .login-row {
    @include flex-space-between;
    @media only screen and (max-width: 768px) {
      display: block;
      width: 85%;
    }
    .left-section {
      margin-right: 270px;
      @media only screen and (max-width: 768px) {
        margin-right: 0;
        margin: 20px 0;
        @include flex-all-center;
      }
      img {
        @media only screen and (max-width: 768px) {
          width: 50%;
        }
      }
    }
    .right-section {
      @include flex;
      @include align-center;
      @media only screen and (max-width: 768px) {
        margin: 50px 0;
      }
      .login-container {
        width: 470px;
        background-color: $defaultcolor;
        padding: 30px;
        margin-bottom: auto;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
        text-align: center;
        @media only screen and (max-width: 768px) {
          width: 100%;
          margin: 0 auto;
        }
        h2 {
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 15px;
          text-align: center;
        }
        p {
          font-size: 14px;
          padding-bottom: 40px;
          color: $grey;
          text-align: center;
          font-weight: 400;
        }
        label {
          font-size: 14px;
          font-weight: 500;
          padding-bottom: 5px;
          display: block;
          text-align: left;
        }
        .form-group {
          margin-bottom: 20px;
          .form-control {
            @include flex;
            flex-direction: column;
            input {
              padding: 13px 15px 16px;
              border: 1px solid #dfdfdf;
              background-color: #fafafa;
              color: $grey;
              font-size: 14px;
            }
            .error-text {
              font-size: 13px;
              color: red;
              margin-top: 5px;
              text-align: left;
            }
          }
        }

        .forgot-password-container {
          text-align: right;
          margin: 25px 0;
          position: relative;
        }

        .forgot-password {
          font-size: 13px;
          font-weight: 600;
          color: $defaultheadingcolor;
          text-decoration: none;
          cursor: pointer;
        }

        .logbtnSec{
          display:flex;
          justify-content: center;
        }
        .login-button {
          margin-top: 30px;
          padding: 12px 95px;
          font-size: 16px;
          color: $defaultcolor;
          background-color: $darkgreen;
          border: none;
          border-radius: 70px;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out;
          display:flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &:hover {
            background-color: $defaultheadingcolor;
          }
          .loading-spinner-container{
            position: relative;
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-top: -7px;
            margin-right: 20px;
          }
          .loading-spinner {
            width: 15px;
            height: 15px;
        }
        }
      }
    }
  }
}

