@import "../../../../../src/assets/styles/shared.scss";

.dashboardWrapper {
  width: 100%;
  .topbar {
    background: $darkgreen;
    height: 69px;
    @include flex-all-center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    i {
      position: absolute;
      top: 13px;
      left: 20px;
      background: rgba(255, 255, 255, 0.5);
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      cursor: pointer;
    }
  }
  .sidebar {
    position: fixed;
    top: 69px;
    left: 0;
    height: 100%;
    width: 260px;
    background: $defaultheadingcolor;
    z-index: 100;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    @media (max-width: 668px) {
      width: 0;
    }
    .nav-links {
      padding: 0;
      width: 100%;
      ::-webkit-scrollbar {
        display: none;
      }
      li {
        position: relative;
        list-style: none;
        transition: all 0.4s ease;
        padding-left: 40px;
        line-height: 50px;
        @media (max-width: 668px) {
          padding-left: 12px;
        }
        .icon-link {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &:hover {
          background: $darkgreen;
        }
        i {
          height: 45px;
          min-width: 50px;
          text-align: center;
          color: #fff;
          font-size: 23px;
          cursor: pointer;
          transition: all 0.3s ease;
          @media (max-width: 668px) {
            opacity: 0;
          }
        }
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
        }
      }
    }
    .bottomLink {
      position: absolute;
      bottom: 69px;
      left: 0;
      width: 100%;
      margin-top: 0;
    }
  }
  .close {
    width: 78px;
    @media (max-width: 668px) {
      width: 260px;
    }
    .logo_name {
      transition-delay: 0s;
      opacity: 0;
      pointer-events: none;
    }
    .nav-links {
      overflow: visible;
      li {
        padding-left: 12px;
        .icon-link {
          display: block;
        }
        a {
          i {
            @media (max-width: 668px) {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 15px;
  font-weight: 400;
  color: $defaultcolor;
  transition: all 0.4s ease;
  @media (max-width: 668px) {
    opacity: 0;
    pointer-events: none;
  }
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
  @media (max-width: 668px) {
    opacity: 1 !important;
    pointer-events: auto !important;
  }
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
}
.sidebar.close ~ .home-section {
  left: 78px;
  width: calc(100% - 78px);
  @media (max-width: 668px) {
    left: 260px !important;
  }
}
.sidebar.close ~ .home-section .repeatSec .boxCol {
  width: 28.8% !important;
}
@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

.addText {
  font-size: 15px;
  color: #ababab;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  i {
    font-size: 25px;
    margin-right: 5px;
  }
  &:hover {
    color: $darkgreen;
  }
}

.flexRow {
  display: flex;
}
.w-85 {
  width: 85%;
}
.react-tel-input .form-control {
  width: 100% !important;
  padding: 13px 15px 16px 51px !important;
  height: 47px !important;
  border: 1px solid #dfdfdf !important;
  background-color: #fafafa !important;
  border-radius: 0 !important;
}
.react-tel-input .flag-dropdown {
  border: 1px solid #dfdfdf !important;
  border-radius: 0 !important;
  padding: 0 4px !important;
}
.react-tel-input .selected-flag:hover {
  background-color: transparent !important;
  border-radius: 0 !important;
}
.react-tel-input .country-list .country {
  padding: 7px 9px;
  text-align: left;
}
.delroundBtn {
  background: #fafafa;
  border: 1px solid #dfdfdf;
  // border-radius: 100%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 22px;
  &:hover {
    background: #ffd7d7;
    border: solid 1px rgb(248, 177, 177);
  }
}
.delroundBtn i {
  font-size: 20px;
  color: #d30b0b;
}
.popClose {
  top: 35px !important;
}

.popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
  }
  .popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 50px 50px;
    z-index: 999;
    width: 700px;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    .bx-x {
      font-size: 40px;
      color: $blackcolor;
      position: absolute;
      right: 40px;
      top: 50px;
      cursor: pointer;
    }
    h2 {
      font-size: 24px;
      color: $blackcolor;
      font-weight: 600;
      padding-bottom: 40px;
    }
    .flexRow {
      display: flex;
      justify-content: space-between;
      .formCol {
        width: 48%;
        .form-group {
          margin-bottom: 20px;
          label {
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 5px;
            display: block;
            text-align: left;
          }
          .form-control {
            @include flex;
            flex-direction: column;
            input {
              padding: 13px 15px 16px;
              border: 1px solid #dfdfdf;
              background-color: #fafafa;
              color: $grey;
              font-size: 14px;
            }
            .error-text {
              font-size: 13px;
              color: red;
              margin-top: 5px;
              text-align: left;
            }
          }
        }
      }
    }
    .btn {
      margin-top: 0;
      padding: 12px 95px;
      font-size: 16px;
      color: $defaultcolor;
      background-color: $darkgreen;
      border: none;
      border-radius: 70px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:hover {
        background-color: $defaultheadingcolor;
      }
      .loading-spinner-container {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-top: -7px;
        margin-right: 20px;
      }
      .loading-spinner {
        width: 15px;
        height: 15px;
      }
    }
  }
  .popup button {
    margin-top: 10px;
  }
}

.spinnerLoad {
  position: absolute;
  top: 70px;
  left: 0;
  width: 96.9%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9;
}

.spinnerLoad2fix {
  position: absolute;
  top: 0;
  left: 0;
  width: 97%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.5);
  z-index: 5;
  padding-top: 100px;
}

.scrollbar {
  float: left;
  min-height: 350px !important;
  overflow-y: auto;
  width: 100%;
  padding-right: 25px;
}
.force-overflow {
  height: 100%;
}
#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}
#style-4::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
}
#style-4::-webkit-scrollbar-thumb {
  background-color: #7d2139;
  border: 1px solid #7d2139;
  border-radius: 5px;
}
.error-text {
  font-size: 13px;
  color: red;
  margin-top: 5px;
  text-align: left;
}

.autocomplete-dropdown-container {
  font-size: 14px;
  color: #3e3e3e;
  text-align: left;
}

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

[data-tooltip]:before {
  position: absolute;
  bottom: 60%;
  left: 30%;
  margin-bottom: 8px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #7d2139;
  background-color: hsla(344, 74%, 29%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 12px;
  line-height: 1.2;
}

[data-tooltip]:after {
  position: absolute;
  bottom: 60%;
  left: 30%;
  margin-left: -8px;
  width: 0;
  border-top: 8px solid #7d2139;
  border-top: 8px solid hsla(344, 74%, 29%, 0.9);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

.greenBtn {
  background-color: #32807f !important;
}

.restaurantTtableSec {
  padding-right: 40px;
  margin-top: 40px;
  @media (max-width: 668px){  
    padding-right: 20px;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  table thead tr th {
    position: relative;
  }
  table thead tr {
    background-color: $defaultcolor;
    border-bottom: 1px solid #848484;
    padding: 0.35em;
    i {
      margin-left: 10%;
      font-size: 30px;
      color: black;
      margin-top: 13px;
      position: absolute;
      top: 0;
      cursor: pointer;
    }
  }

  table tr {
    background-color: $defaultcolor;
    border-bottom: 1px solid #d9d9d9;
    padding: 0.35em;
    position: relative;
    @media (max-width: 668px){  
      padding: 0;
    }
  }

  table th,
  table td {
    padding: 1rem 0.625em;
    text-align: left;
    word-wrap: break-word;
    @media (max-width: 1300px){  
      padding: 1.4rem 1em;
    }
    @media (max-width: 668px){  
      padding: 1.4rem 0;
    }
    &:last-child{
      padding: 1rem 0 1rem 0.625em;
      width: auto;
      @media (max-width: 1500px){  
        padding: 1rem 0 1rem 0;     
        width: auto !important;
      } 
    }
  }
  table th {
    font-size: 20px;
    @media (max-width: 1500px){  
      font-size: 17px;
      }  
      @media (max-width: 1300px){  
        font-size: 15px;
        }  
  }        
  table td {
    font-size: 16px;
    @media (max-width: 1500px){  
      font-size: 15px;
      }
      @media (max-width: 1300px){  
        font-size: 13px;
        }
  }

  @media screen and (max-width: 1000px) {
    table {
      border: 0;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      display: block;
      margin-bottom: 0.625em;
    }

    table td {
      display: block;
      font-size: 0.8em;
      text-align: right;
    }

    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
  .ignoreBtn {
    background: $yellowbg;
    border-radius: 50px;
    color: $defaultcolor;
    padding: 10px 0;
    width: 165px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    margin-right: 10px;          
    @media (max-width: 1600px){  
      padding: 12px 0;
    }
    @media (max-width: 1400px){  
      padding: 12px 20px;
      font-size: 14px;
    }
    @media (max-width: 1200px){  
      padding: 8px 15px;
      font-size: 14px;
    }
    @media (max-width: 900px){  
      padding: 8px 15px;
      font-size: 14px;
      width: auto;
    }
    
  }
  .revraBtn {
    background: #7D2139;
    border-radius: 50px;
    color: $defaultcolor;
    padding: 10px 0;
    width: 165px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    // &:hover{
    //   background: $yellowbg;
    // }
    @media (max-width: 1600px){  
      padding: 12px 0;
    }
    @media (max-width: 1400px){  
      padding: 12px 20px;
      font-size: 14px;
    }
    @media (max-width: 1200px){  
      padding: 8px 15px;
      font-size: 14px;
    }
    @media (max-width: 900px){  
      padding: 8px 15px;
      font-size: 14px;
      width: auto;
    }
  }
  .textEnd {
    text-align: right;
    cursor: pointer;
  }
}


.css-1nmdiq5-menu {
  border: 1px solid #dfdfdf !important;
  background-color: #fff !important;
  z-index: 999999;
}