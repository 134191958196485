@import "../../../../../src/assets/styles/shared.scss";

.dashboardWrapper {
  width: 100%;
  .home-section {
    position: relative;
    background: $defaultcolor;
    height: 83vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
    margin-top: 69px;
    padding: 30px 0;
    @media (max-width: 1500px){ 
      height: 60vh;
      padding: 30px 0 0;
    }
    @media (max-width: 668px){ 
      left: 0;
      width: calc(100% - 0px);
      height: auto;
    }
    .home-content {
      padding: 0 0 0 40px;
      @media (max-width: 668px){ 
        padding: 0 0 0 20px;
      }
      .bodyTopsec {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        @media (max-width: 668px){ 
          flex-wrap: wrap;
          padding-right: 20px;
        }
        h2 {
          color: $blackcolor;
          font-size: 28px;
          font-weight: 600;
          @media (max-width: 668px){ 
            font-size: 22px;
          }
        }
        .iconSce {
          display: flex;
          @media (max-width: 668px){ 
            width: 100%;
            margin-top: 20px;
          }
          .addIcon{
            background: $darkgreen;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            cursor: pointer;
          }
          .bxs-file-export {
            background: $darkgreen;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            cursor: pointer;
            color: $defaultcolor;
          }
        }
      }
    }
  }
}


.comingSec{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}