@import "../../../../../src/assets/styles/shared.scss";

.dashboardWrapper {
  width: 100%;
  .home-section {
    .homeContent {
      padding: 0 0 0 40px;
      @media (max-width: 668px){ 
        padding: 0 0 0 20px;
      }
      .bodyTopsec {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        align-items: center;
        @media (max-width: 668px){ 
          padding-right: 20px;
          flex-wrap: wrap;
          align-items: center;
        }
        h2 {
          color: $blackcolor;
          font-size: 28px;
          font-weight: 600;
          @media (max-width: 668px){ 
            font-size: 22px;
          }
        }
            .doneBtn3 {
                padding: 12px 70px;
                font-size: 16px;
                color: $defaultcolor;
                background-color:#FEC933;
                border: none;
                border-radius: 70px;
                cursor: pointer;
                transition: background-color 0.3s ease-in-out;
                float: right;
                @media (max-width: 668px){ 
                  padding: 12px 40px;
                }
                &:hover{
                  background-color: $defaultheadingcolor;
                }
              }
              .cancleBtn2 {
                padding: 12px 70px;
                font-size: 16px;
                color: $darkgreen;
                background-color: $defaultcolor;
                border:solid 2px $darkgreen;
                border-radius: 70px;
                cursor: pointer;
                transition: background-color 0.3s ease-in-out;
                float: left;
                text-decoration: none;
                margin-right: 15px;
                @media (max-width: 668px){ 
                  padding: 12px 40px;
                }
                &:hover{
                  background-color: $darkgreen;
                  color: $defaultcolor;
                }
              }
            
        .iconSce {
          display: flex;
          @media (max-width: 668px){ 
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .bxs-pencil {
            background: $yellowbg;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            cursor: pointer;
            margin-right: 15px;
            color: $defaultcolor;
          }
          .bx-plus {
            background: $darkgreen;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            cursor: pointer;
            color: $defaultcolor;
          }
          .doneBtn {
            border: solid 3px $yellowbg;
            border-radius: 30px;
            padding: 10px 65px;
            color: #fec933;
            text-decoration: none;
            line-height: 23px;
            margin-right: 15px;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            @media (max-width: 668px){ 
              padding: 10px 25px;
            }
            &:hover {
              background: $yellowbg;
              color: $blackcolor;
            }
          }
        }
      }
    
      .repeatSec {
        width: 100%;
        margin-top: 20px;
        h3 {
          font-size: 20px;
          color: $blackcolor;
          font-weight: 600;
          padding-bottom: 10px;
          label {
            position: relative;
            cursor: pointer;
            &:before {
              content:'';
              appearance: none;
              background-color: transparent;
              border: 2px solid $blackcolor;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
              padding: 8px;
              border-radius: 3px;
              display: inline-block;
              position: relative;
              vertical-align: middle;
              cursor: pointer;
              margin-right: 10px;
              margin-top: -2px;
            }
          }
          input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
            &:checked + label:after {
              content: '';
              display: block;
              position: absolute;
              top: 5px;
              left: 7px;
              width: 4px;
              height: 10px;
              border: solid $blackcolor;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
          i{
            color: $darkgreen;
            font-size: 15px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
        .dealflexRow {
          display: flex;
          flex-wrap: wrap;
          .boxCol {
            background: rgba(246, 246, 246, 1);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 12px 0;
            width: 28%;
            margin-right: 20px;
            margin-bottom: 15px;
            @media (max-width: 668px){ 
              width: 100%;
              margin-right: 0;
            }
            .flxr{
              display: flex;
              padding: 0 20px;
              label {
                position: relative;
                cursor: pointer;
                width: 100%;
                &:before {
                  content:'';
                  appearance: none;
                  background-color: transparent;
                  border: 2px solid $blackcolor;
                  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                  padding: 8px;
                  border-radius: 3px;
                  display: inline-block;
                  position: absolute;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-right: 10px;
                  margin-top: 17px;
                }
              }
              input {
                padding: 0;
                height: initial;
                width: initial;
                margin-bottom: 0;
                display: none;
                cursor: pointer;
                &:checked + label:after {
                  content: '';
                  display: block;
                  position: absolute;
                  top: 19px;
                  left: 7px;
                  width: 4px;
                  height: 10px;
                  border: solid $blackcolor;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                }
              }
            }
            .flexSec {
              display: flex;
              justify-content: space-between;
              .iconSec {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .bxs-pencil {
                  background: $yellowbg;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
                  border-radius: 100%;
                  cursor: pointer;
                  color: $defaultcolor;
                  @media (max-width: 668px){ 
                    width: 35px;
                    height: 35px;
                    font-size: 15px;
                  }
                }
                .bxs-trash {
                  background: $defaultheadingcolor;
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 40px;
                  height: 40px;
                  border-radius: 100%;
                  cursor: pointer;
                  color: $defaultcolor;
                  margin-right: 10px;
                  @media (max-width: 668px){ 
                    width: 35px;
                    height: 35px;
                    font-size: 15px;
                  }
                }
              }
            }
            p {
              font-size: 16px;
              padding: 4px 0 4px 35px;
            }
          }
        }
      }
    }
    .prcon20{
      padding-right: 0;
      @media (max-width: 668px) {
        padding-right: 20px !important;
      }
    }
  }
}

.alertMessage {
  color: red;
  font-size: 14px;
}