@import "../../../../../../src/assets/styles/shared.scss";



.dashboardWrapper {
  width: 100%;
  .topbar {
    background: $darkgreen;
    height: 69px;
    @include flex-all-center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    i {
      position: absolute;
      top: 13px;
      left: 20px;
      background: rgba(255, 255, 255, 0.5);
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      cursor: pointer;
    }
  }

}



.tabSecrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0;
  margin-top: 50px;
  margin-right: 40px;
  @media (max-width: 668px){
    margin-right: 20px;
  }
  .tabs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .detailsTabs {
      order: 1;
      display: block;
      padding: 0.7rem 3rem;
      margin-right: 0.2rem;
      cursor: pointer;
      background: rgba(218, 218, 218, 1);
      transition: background ease 0.2s;
      border-radius: 10px 10px 0 0;
      font-size: 14px;
      color: #7A7A7A;
      border: solid 1px rgba(189, 189, 189, 1);
      font-weight: 600;
      @media (max-width: 668px){
        padding: 0.7rem 1rem;
      }
      span{
        @media (max-width: 668px){
          display: none;
        }
      }
    }
    .tab {
      order: 99; // Put the tabs last
      flex-grow: 1;
      width: 100%;
      background: #F6F6F6;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    }
  }
}

.selectedTabs{
  background: rgba(246, 246, 246, 1) !important;
  border: solid 1px rgba(246, 246, 246, 1) !important;
  color: #000 !important;
}

.repeatSecmargin{
  margin: 20px 30px 0;
  width: auto !important;
}


.repeatSecmargin2{
  margin: 20px 10px 0 30px!important;
  width: auto !important;
}
.repeatSecmargin3{
  margin: 20px !important;
  width: auto !important;
  border-bottom: 2px solid #dddddd;
}
.repeatSec{
  .flexRow2{
    justify-content: flex-start !important;
    .boxCol3 {
      width: 28% !important;
      margin-right: 20px !important;
      @media (max-width: 668px){
        width: auto !important;
      }
    }
  }
}
.filterSec{
  position: relative;
  margin-right: 10px;
  margin-top: 8px;
  a{
    background: #fff;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
    margin-right: 15px;
    color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  .countSec{
    background: #fec933;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    position: absolute;
    top: 1px;
    right: 13px;
  }
}

.bx-chevron-down{
  font-size: 30px;
}
.bx-chevron-up{
  font-size: 30px;
}

.loadMoreText{
  width: 95%;
  font-size: 15px;
  background-color: #7d2139;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  border-radius: 10px;
  margin: 0 auto;

}



.scrollbar2{
	width: 100% !important;
	max-height: 450px;
  overflow: auto;
  margin-right: 5px;
}
.force-overflow{
	max-height: 100%;
}
#style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;  
}

#style-4::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5; 
  -webkit-appearance: none; 
}

#style-4::-webkit-scrollbar-thumb
{
	background-color: #7d2139;
	border: 2px solid #555555;
}


// .scrollbar2::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 9px;
//   height: 9px;
// }
// .scrollbar2::-webkit-scrollbar-thumb {
//   background-color: #A8A8A8;
//   -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
// }
// .scrollbar2::-webkit-scrollbar-track {
//   background: #F1F1F1;
// }



.scrollbar22{
	width: 97% !important;
	float: inherit;
	max-height: 370px;
  margin: 5px;
  overflow-y: scroll;
}
.force-overflow22{
	max-height: 350px;
}
#style-6::-webkit-scrollbar-track{
	background-color: #dadada;
  border-radius: 5px;
}
#style-6::-webkit-scrollbar{
	width: 5px;
	background-color: #dadada;
  border-radius: 5px;
}
#style-6::-webkit-scrollbar-thumb{
	background-color: #7d2139;
	border: 2px solid #555555;
  border-radius: 5px;
}


.tabscroll{
  min-height: 450px;
}
.tabLocation{
  padding-top: 20px;
}



 

.locationTable thead, .locationTable tbody tr{
  display: table;
  width: 100%;
  table-layout: fixed;
}