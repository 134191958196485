@import "../../../../../src/assets/styles/shared.scss";

.popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
  }
  .popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 50px 50px;
    z-index: 999;
    width: 700px;
    border-radius: 30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    .bx-x {
      font-size: 40px;
      color: $blackcolor;
      position: absolute;
      right: 40px;
      top: 50px;
      cursor: pointer;
    }
    h2 {
      font-size: 24px;
      color: $blackcolor;
      font-weight: 600;
      padding-bottom: 40px;
    }
    .flexRow {
      display: flex;
      justify-content: space-between;
      .formCol {
        width: 48%;
        .form-group {
          margin-bottom: 20px;
          label {
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 5px;
            display: block;
            text-align: left;
          }
          .form-control {
            @include flex;
            flex-direction: column;
            input {
              padding: 13px 15px 16px;
              border: 1px solid #dfdfdf;
              background-color: #fafafa;
              color: $grey;
              font-size: 14px;
            }
            .error-text {
              font-size: 13px;
              color: red;
              margin-top: 5px;
              text-align: left;
            }
          }
        }
      }
    }
    .btn {
      margin-top: 0;
      padding: 12px 95px;
      font-size: 16px;
      color: $defaultcolor;
      background-color: $darkgreen;
      border: none;
      border-radius: 70px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:hover {
        background-color: $defaultheadingcolor;
      }
      .loading-spinner-container {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-top: -7px;
        margin-right: 20px;
      }
      .loading-spinner {
        width: 15px;
        height: 15px;
      }
    }
  }
  .popup button {
    margin-top: 10px;
  }
}

.spinnerLoad {
  position: absolute;
  top: 70px;
  left: 0;
  width: 96.9%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9;
}

.spinnerLoad2fix {
  position: absolute;
  top: 0;
  left: 0;
  width: 97%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.5);
  z-index: 5;
  padding-top: 100px;
}

.scrollbar {
  float: left;
  min-height: 350px !important;
  overflow-y: auto;
  width: 100%;
  padding-right: 25px;
}
.force-overflow {
  height: 100%;
}

.bx-git-merge {
  background: $darkgreen;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
  color: $defaultcolor;
}
.bx-show {
  background: #32807f;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
  color: white;
}
.bx-reset {
  background: #7D2139;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
  color: white;
}
.mergeDisabled {
  background: $grey;
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #7D2139;
  background-color: white;
  position: relative;
  cursor: pointer;
  outline: none;
}

.checkbox:checked {
  background-color: #7D2139;
}

.checkbox:checked::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.dblock{
  display: block !important;
}
.searchPart{
  width: 400px;
  display: flex;
}