@import "../../../../../src/assets/styles/shared.scss";
 
.dashboardWrapper {
  width: 100%;
  .home-section {
    position: relative;
    background: $defaultcolor;
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
    margin-top: 69px;
    padding: 30px 0;
    .home-content {
      padding: 0 0 0 40px;
      .bodyTopsec {
        display: flex;
        justify-content: space-between;
        padding-right: 40px;
        h2 {
          color: $blackcolor;
          font-size: 28px;
          font-weight: 600;
        }
      }
 
      .repeatSec2 {
        width: 100%;
        margin-top: 20px;
        a{
          text-decoration: none;
        }
        h3 {
          font-size: 16px;
          color: #252525;
          font-weight: 600;
          padding-bottom: 0;
          // display: flex;
          // flex-wrap: wrap;
          align-items: center;          
          i{
            color: $darkgreen;
            font-size: 15px;
            margin-left: 10px;
          }
        }
        .flexRow {
          display: flex;
          flex-wrap: wrap;
          .boxCol {
            background: rgba(246, 246, 246, 1);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 12px 20px;
            width: 44.5%;
            margin-right: 20px;
            margin-bottom: 15px;
            @media (max-width: 668px){ 
              width: 100%;
            }
            p {
              font-size: 16px;
              padding: 4px 0;
              color: #7C7C7C;
            }
          }
        }
      }
    }
  }
}