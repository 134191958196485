@import "./fonts";
@import "./variable";
@import "./mixin";

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  background: $defaultcolor;
  font-weight: 500;
}
p,
h2,
h1,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
  word-wrap: break-word;
}
.img {
  @include img;
}
.mlauto {
  margin-left: auto !important;
}
.mrauto {
  margin-right: auto !important;
}
.margin10 {
  margin-top: 10px;
}
.margin20 {
  margin-top: 20px !important;
}
.margin30 {
  margin-top: 30px !important;
}
.margin40 {
  margin-top: 40px;
}
.pb10 {
  padding-bottom: 10px;
}
.margin50 {
  margin-top: 50px !important;
}
.marginLeft10 {
  margin-left: 10px;
}
.mtm30 {
  margin-top: -30px !important;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
    width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
    width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
    width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
    width: 1140px;
  }
}

input,
textarea,
select {
  font-family: "Inter", sans-serif;
}

.containerfluid {
  max-width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.ul {
  @include ul;
}
.cdark {
  color: $blackcolor !important;
}
.cwhite {
  color: $defaultcolor !important;
}
.btn {
  border: none;
  outline: none;
  transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  padding: 15px 30px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  text-decoration: none;
}
.btn:disabled {
  pointer-events: none;
  background: #fff;
  color: #000;
  border: solid 1px $grey;
}

.selectTag .css-1vsd43b-control {
  padding: 10px 5px 12px;
  border: 1px solid #dfdfdf;
  background-color: #fafafa;
  color: #ababab;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border-radius: 0;
  min-height: auto;
  margin-top: 5px !important;
}
.css-124rl6k-control {
  padding: 10px 5px 12px;
  border: 1px solid #dfdfdf;
  background-color: #fafafa;
  color: #ababab;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border-radius: 0;
  min-height: auto;
  margin-top: 5px !important;
}
.css-10gn4v7-MultiValueGeneric {
  padding-left: 10px !important;
  padding-top: 7px !important;
}
.css-pjm3ym-MultiValueRemove {
  padding: 0;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
}
.form-select {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    background: url(../images/search-icon.png) 0 0 no-repeat;
    left: 0;
    width: 20px;
    height: 20px;
  }
  .wsol-select {
    padding: 13px 15px 16px;
    border: 1px solid #dfdfdf;
    background-color: #fafafa;
    color: #ababab;
    font-size: 14px;
    width: 100%;
    margin-top: 5px;
  }
}

.selectCategory .css-1vsd43b-control {
  padding: 10px 5px 12px;
  border: 1px solid #dfdfdf;
  background-color: #fafafa;
  color: #ababab;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border-radius: 0;
  min-height: auto;
  margin-top: 5px;
}
.categoryDropSec {
  background: #fafafa;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  text-align: left;
  font-size: 14px;
  color: #484848;
  padding: 15px 15px;
  border: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 22px;
    color: $darkgreen;
    margin-right: 7px;
  }
}
.popup.zIndex999 .popup-content {
  z-index: 9999;
}
.zIndex999:after {
  z-index: 999;
}
.selectCategory .css-124rl6k-control {
  margin-top: 5px !important;
}
.css-1nmdiq5-menu {
  text-align: left;
  font-size: 14px;
  background-color: #fff !important;
  z-index: 999999;
}
.error-message {
  color: red;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.deal-error-message {
  color: red;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: start;
}
.success-message {
  color: green;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.pagination {
  list-style: none;
  padding: 0 0 50px;
  margin: 50px 0 0;
  display: flex;
  justify-content: center;
  li {
    margin: 0 5px;
    a {
      background: $defaultcolor;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      width: 33px;
      height: 33px;
      text-align: center;
      font-size: 15px;
      color: $blackcolor;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      i {
        font-size: 20px;
      }
      &:hover {
        background: $darkgreen;
        color: $defaultcolor;
      }
    }
    &:last-child {
      a {
        i {
          margin-left: 2px;
        }
      }
    }
  }
  .active {
    a {
      background: $darkgreen;
      color: $defaultcolor;
    }
  }
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.catselectDropcustom .css-1nmdiq5-menu {
  border: 1px solid #dfdfdf !important;
  background-color: #fff !important;
  z-index: 999999;
}

.fullcolWidth100 {
  width: 100% !important;
}

.doneBtn {
  border: solid 3px $yellowbg;
  border-radius: 30px;
  padding: 10px 65px;
  color: #fec933;
  text-decoration: none;
  line-height: 23px;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background: $yellowbg;
    color: $blackcolor;
  }
}

table th,
table td {
  word-wrap: break-word;
}
.textCenter1 {
  text-align: center !important;
  @media (max-width: 668px) {
    text-align: right !important;
  }
}

.titleWidth {
  width: 80%;
  @media (max-width: 668px) {
    width: 100%;
  }
}

.titleWidth50 {
  width: 45%;
  @media (max-width: 668px) {
    width: 100%;
  }
}
