.delresPopup{
    .popup-content{
      width: 500px !important;
      z-index: 9999;
      @media (max-width: 668px) {
        width: 75%;
      }
      h2{
        padding-bottom: 25px;
      }
      p{
        color: #242424;
        font-size: 16px;
        padding: 0 50px;
      }
      .delCancelBtn{
        margin-right: 15px;
        padding: 10px 80px !important;
        margin-top: 0 !important;
        font-weight: 500;
        border: solid 2px #32807F;
        border-radius: 70px;
        background: white;
        color: #32807F;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        margin-left: 10px;        
        &:hover{
          background:#32807F;
          color: #fff;
        }
      }
  .delSubmitBtn2{
    margin-right: 15px;
    padding: 10px 30px !important;
    margin-top: 0 !important;
    font-weight: 500;
    border: solid 2px #C22326;
    border-radius: 70px;
    background: #C22326;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    display: flex;
    min-width: 250px;
    justify-content: center;
    @media (max-width: 668px) {
      padding: 10px 15px !important;
      margin-left: 0;
      font-size: 14px;
      margin-right: 0;
      min-width: auto;
    }
    &:hover{
      background:#911618;
      border: solid 2px #911618;
    }
  }  
    }
    &:after{
      z-index: 999;
    }  
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
  }

  .loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.loading-text {
  margin-top: 10px;
}

.loading-text p {
  display: block;
  max-width: 400px;
  margin: 5px 0;
}

.disSubmitBtn {
  background: #949090 !important;
  border: solid 2px #949090 !important;
}

.paraText {
  line-height: 1.7;
  font-size: 15px !important;
  margin-top: 15px;
}

.paraText1 {
  line-height: 1.7;
  font-size: 15px !important;
}

.scrollable-container::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #7d2139;
  border: 1px solid #7d2139;
  border-radius: 5px;
}

.scrollable-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}