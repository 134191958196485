@import "../../../../../src/assets/styles/shared.scss";

.flexRow{
    display: flex;
    justify-content: space-between;
    .formCol{
      width: 48%;
      label {
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 8px;
        display: block;
        text-align: left;
      }
      select {
          padding: 13px 15px 16px;
          border: 1px solid #DFDFDF;
          background-color: #FAFAFA;
          color: $blackcolor;
          font-size: 16px;
          width: 100%;
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
          background-position: right 15px center;
          background-repeat: no-repeat;
          background-size: auto 50%;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          &:focus{
              outline: none;
          }
        }
        .error-text {
          font-size: 13px;
          color: red;
          margin-top: 5px;
          text-align: left;              
        }
    }
    .fullcolWidth100{
      width: 100% !important;
    }
  }

  .importCsvrightGap{
    padding-right: 40px !important;
    width: auto !important;
  }

  .file-upload-wrapper {
    $defaultColor: #4daf7c;
    $height: 60px;
    
    position: relative;
    width: 100%;
    height: $height;
    
    &:after {
      content: attr(data-text);
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #FAFAFA;
      border: 1px solid #DFDFDF;
      padding: 5px 15px;
      display: block;
      width: calc(100% - 40px);
      pointer-events: none;
      z-index: 20;
      height: $height - 20px;
      line-height: $height - 20px;
      color: $blackcolor;
      font-weight: 400;
    }
    
    &:before {
      content: 'Upload File';
      position: absolute;
      top: 0;
      right: 9px;
      display: inline-block;
      height: 52px;
      background: #32807f;
      color: #fff;
      font-weight: 500;
      z-index: 25;
      font-size: 16px;
      line-height: 50px;
      padding: 0 20px;
    }
    
    &:hover {
      &:before {
        background: darken(#32807f, 10%);
      }
    }
     input {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 99;
      height: $height - 20px;
      margin: 0;
      padding: 0;
      display: block;
      cursor: pointer;
      width: 100%;
    }
  }

  .importBtn{
    margin-top: 0;
    padding: 15px 60px;
    font-size: 18px;
    color: white;
    background-color: #32807f;
    border: none;
    border-radius: 70px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: auto;
    &:hover{
      background-color: #7d2139;
    }
  }
  .disImportBtn {
    background-color: #7d2139 !important;
  }

  .progress-bar {
    width: 50%;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .progress-bar-fill {
    height: 24px;
    background: repeating-linear-gradient(
      45deg,
      #4caf50,
      #4caf50 10px,
      #66bb6a 10px,
      #66bb6a 20px
    );
    text-align: center;
    line-height: 24px;
    color: white;
    transition: width 0.25s;
  }